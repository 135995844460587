<template>
  <div>
    <a-modal v-model="visible" title="物料选择" width="900px" :footer="null" @cancel="onCloseModel">
      <a-row :gutter="16">
        <a-col :span="12" style="width: 256px;">
          <a-input v-model="searchForm.search" placeholder="产品名称, 编码, 条码" allowClear @pressEnter="search" />
        </a-col>
        <a-space>
          <a-button type="primary" @click="confirmSelected">确认</a-button>
        </a-space>
        <a-col :span="12" style="width: 256px;">
            <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>
      </a-row>

      <div style="margin-top: 12px;">
        <a-table :data-source="items" rowKey="id" :columns="columns" :loading="loading" :pagination="pagination"
          @change="onChangeTable"
          :row-selection="{
            type: isRadio ? 'radio' : 'checkbox',
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange
          }">
          <!-- <div slot="action" slot-scope="value, item, index">
            <a-button size="small" @click="select(item)">选择</a-button>
          </div> -->
        </a-table>
      </div>

    </a-modal>
  </div>
</template>

<script>
  import { inventoriesOption } from '@/api/option';
  import { columns } from './columns';

  export default {
    props: ['visible', 'warehouse'],
    model: { prop: 'visible', event: 'cancel' },
    data() {
      return {
        columns,
        pagination: {},
        searchForm: { search: '' },
        loading: false,
        items: [],
        isRadio: false,
        selectedRowKeys:[],
        selectedRows: [],
      }
    },
    methods: {
      onCloseModel() {
        this.$emit('cancel', false);
      },
      onChangeTable(pagination, filters, sorter) {
        this.pagination = pagination;
        this.searchForm.page = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
      search() {
        this.pagination.current = 1;
        this.searchForm.page = 1;
        this.list();
      },
      list() {
        this.loading = true;
        inventoriesOption(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;
        }).finally(() => {
          this.loading = false;
        });
      },
      select(item) {
        this.$emit('select', item);
        this.onCloseModel();
      },
      confirmSelected() {
        this.$emit('select', this.selectedRows);
        this.onCloseModel();
      },
      resetModel() {
        this.pagination = { current: 1, total: 0, pageSize: 15, showTotal: total => `共 ${total} 条` };
        this.searchForm = { page: 1, warehouse: this.warehouse, is_active: true };
        this.items = [];
      },
      onSelectChange(selectedRowKeys, selectedRows) {
        let self = this;
        this.selectedRowKeys = selectedRowKeys;
        selectedRows.forEach(function(item){
          if(self.selectedRows.indexOf(item) < 0){
            // 数量默认为1
            item.totalNums = 1
            self.selectedRows.push(item);
          }
        });
        // 去重
        self.selectedRows.forEach(function(item){
          if(selectedRowKeys.indexOf(item.id) < 0){
            self.selectedRows.splice(self.selectedRows.indexOf(item), 1);
          }
        })
        let arr = this.deWeight(self.selectedRows)
        self.selectedRows = arr
      },
      // 数组去重
      deWeight(list) {
        return list.reduce((unique, o) => {
          // 去除id相同的对象
          if(!unique.some(obj => obj.id === o.id)) {
            unique.unshift(o)
          }
          return unique
        },[])
      },
      setSelectedRowKeys(params, rows) {
        this.selectedRowKeys = JSON.parse(JSON.stringify(params))
        this.selectedRows = JSON.parse(JSON.stringify(rows))
        // 由于去重试取的是产品id，此时传入数组的goods为产品id
        this.selectedRows.forEach(item => {
          item.id = item.goods
        })
      },
      setRadio() {
        this.isRadio = true
      }
    },
    watch: {
      visible(status) {
        if (status) {
          this.resetModel();
          this.list();
        }
      },
    },
  }
</script>
